<template>
  <div ref="root" class="text-white min-h-10 bg-dark-blue">
    <div
      class="container relative h-full text-center py-2"
      @click="contentClick"
      v-html="content"
    />
  </div>
</template>

<script setup lang="ts">
defineProps<{ content: string }>();

const root = ref();

const isVisibleDiscountCompaign = useElementVisibility(root);

const contentClick = useContentLink;

const { height } = useElementBounding(root);

watch(height, () => {
  setCustomProperty("--discount-compaign-height", String(`${height.value}px`));
});

watch(isVisibleDiscountCompaign, () => {
  setCustomProperty(
    "--discount-compaign-visible-height",
    String(isVisibleDiscountCompaign.value ? `${height.value}px` : "0px"),
  );
});
</script>
